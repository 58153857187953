.containers {
    display: flex;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    flex-direction: row;
}

.sidebar {
    width: 250px; /* 사이드바 크기를 줄임 */
    background-color: var(--primary-color);
    padding: 20px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    border-radius: var(--border-radius);
    color: white; /* 텍스트 색상 흰색으로 변경 */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.main {
    display: flex;
    padding: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    font-family: var(--font-family);
    font-size: var(--font-size);
    color: var(--text-color);
}

@media (max-width: 769px) {
    .containers {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        flex-direction: column;
        box-sizing: border-box;
    }

    .sidebar {
        width: 100%; /* 사이드바 크기를 줄임 */
        height: 60px;
        background-color: var(--primary-color);
        box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
        border-radius: 0;
        padding: 0;
        color: white; /* 텍스트 색상 흰색으로 변경 */
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: center;
        overflow-x: auto;
    }

    .main {
        display: flex;
        width: 100%;
        height: 100%;
        padding: 0;
        flex-direction: column;
        font-family: var(--font-family);
        font-size: var(--font-size);
        color: var(--text-color);
    }
}
