.contextMenu {
    position: fixed;
    background: white;
    border: 1px solid #ccc;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    border-radius: 5px;
    overflow: hidden;
}

.menuItem {
    display: block;
    padding: 10px 20px;
    border: none;
    background: white;
    cursor: pointer;
    width: 100%;
    text-align: left;
    transition: background 0.3s;
}

.menuItem:hover {
    background: #f1f1f1;
}
