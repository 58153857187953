:root {
    --primary-color: #ff6b6b;
    --secondary-color: #ffe66d;
    --highlight-color: #4ecdc4;
    --background-color: #f7fff7;
    --text-color: #4a4a4a;
    --border-radius: 12px;
}

.modalBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modalContent {
    background-color: white;
    padding: 20px;
    border-radius: var(--border-radius);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 300px;
    max-height: 80%;
    overflow-y: auto;
    z-index: 1001;
}

.inputContainer {
    margin-bottom: 20px;
}

.label {
    display: block;
    margin-bottom: 5px;
    color: var(--text-color);
}

.input {
    width: 100%;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: var(--border-radius);
    margin-bottom: 10px;
}

.updateButton {
    padding: 10px;
    background-color: var(--highlight-color);
    color: white;
    border: none;
    border-radius: var(--border-radius);
    cursor: pointer;
    width: 100%;
    margin-bottom: 10px;
    transition: background-color 0.3s;
}

.updateButton:hover {
    background-color: #3bb3a6;
}

.closeButton {
    padding: 10px;
    background-color: #ccc;
    color: white;
    border: none;
    border-radius: var(--border-radius);
    cursor: pointer;
    width: 100%;
    transition: background-color 0.3s;
}

.closeButton:hover {
    background-color: #bbb;
}
