.modalBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modalContent h2, .modalContent label {
    color: black;
}

.modalContent {
    background-color: white;
    padding: 20px;
    border-radius: var(--border-radius);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 300px;
    max-height: 80%;
    overflow-y: auto;
    z-index: 1001;
}

.input {
    width: calc(100% - 20px);
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: var(--border-radius);
}

.addButton {
    padding: 10px;
    background-color: var(--highlight-color);
    color: white;
    border: none;
    border-radius: var(--border-radius);
    cursor: pointer;
    width: 100%;
    margin-top: 10px;
    transition: background-color 0.3s;
}

.addButton:hover {
    background-color: #3bb3a6;
}

.closeButton {
    padding: 10px;
    background-color: #ccc;
    color: white;
    border: none;
    border-radius: var(--border-radius);
    cursor: pointer;
    width: 100%;
    margin-top: 10px;
    transition: background-color 0.3s;
}

.closeButton:hover {
    background-color: #bbb;
}
