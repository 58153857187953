.signupContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f4f4f4;
  }
  
  .signupBox {
    background-color: #fff;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 400px;
    width: 100%;
  }
  
  .logo {
    width: 80px;
    height: 80px;
    margin-bottom: 20px;
  }
  
  .serviceName {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .serviceNameKorean {
    font-size: 20px;
    margin-bottom: 20px;
    color: #777;
  }
  
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .input {
    padding: 10px;
    /* margin-bottom: 15px; */
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    width: 100%;
    box-sizing: border-box;
  }

  .input_etc {
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    width: 100%;
    box-sizing: border-box;
  }
  
  .signupButton {
    background-color: #007bff;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
  }
  
  .signupButton:hover {
    background-color: #0056b3;
  }
  
  .loginLink {
    margin-top: 20px;
    font-size: 14px;
    color: #007bff;
  }
  
  .loginLink a {
    color: #007bff;
    text-decoration: none;
  }
  
  .loginLink a:hover {
    text-decoration: underline;
  }
  
  .emailContainer, .verificationContainer {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    width: 100%;
  }
  
  .emailContainer .input, .verificationContainer .input {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .verifyButton {
    background-color: #28a745;
    width: 150px;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    margin-left: 10px;
  }
  
  .verifyButton:hover {
    background-color: #218838;
  }
  