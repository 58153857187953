:root {
    --primary-color: #2c3e50; /* 고급스러운 어두운 회색 */
    --secondary-color: #ecf0f1; /* 밝은 회색 */
    --accent-color: #27ae60; /* 부드러운 녹색 */
    --background-color: #f9f9f9; /* 밝은 회색 */
    --text-color: #333333; /* 진한 회색 */
    --border-radius: 8px; /* 테두리 반경 */
}

/* 기본 스타일 */
.challengeList {
    list-style-type: none;
    padding: 0;
}

.challengeItem {
    padding: 10px;
    margin: 10px 0;
    background-color: var(--secondary-color);
    border: 1px solid #ddd;
    border-radius: var(--border-radius);
    cursor: pointer;
    transition: background-color 0.3s;
    color: var(--text-color);
}

.challengeItem:hover {
    background-color: #d0d0d0; /* 더 진한 회색 */
}

.addButton {
    display: block;
    padding: 10px;
    background-color: var(--accent-color); /* 부드러운 녹색 */
    color: white;
    border: none;
    border-radius: var(--border-radius);
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: auto;
}

.addButton:hover {
    background-color: #229954; /* 호버 시 더 진한 녹색 */
}

/* 모바일 반응형 스타일 */
@media (max-width: 769px) {
    .listLogo {
        display: none;
    }

    .challengeList {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .challengeItem {
        margin-left: 30px;
        white-space: nowrap; 
    }

    .addButton {
        display: inherit;
        width: 100px;
        padding: 10px;
        white-space: nowrap; 
        background-color: var(--accent-color); /* 부드러운 녹색 */
        color: white;
        border: none;
        border-radius: var(--border-radius);
        cursor: pointer;
        transition: background-color 0.3s;
        margin: 0;
        margin-left: 10px
    }

}
