.loginContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f2f5; /* Light gray background */
}

.loginBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 350px; /* Ensures consistent width */
    box-sizing: border-box; /* Include padding and border in element's total width and height */
}

.logo {
    width: 100px; /* Adjust the width of the logo */
    margin-bottom: 20px;
}

.serviceName {
    font-size: 24px;
    font-weight: bold;
    text-align: center; /* Center text */
    margin-bottom: 20px;
}

.input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    box-sizing: border-box; /* Include padding and border in element's total width and height */
}

.loginButton {
    width: 300px;
    padding: 10px;
    background-color: #3897f0; /* Blue button */
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 10px; /* Ensure consistent spacing */
}

.loginButton:hover {
    background-color: #287abf;
}

.signupLink {
    margin-top: 20px;
    font-size: 14px;
}

.signupLink a {
    color: #3897f0;
    text-decoration: none;
    font-weight: bold;
}
